import { KeyedMutator } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { PJP_COMPANY_URL } from '@constants/url'

import { PjpCompanyPropsPayload } from '@custom-types/company'

type UsePjpCompaniesProps<Data> = {
  pjpCompaniesData: Data
  isLoadingPjpCompanies: boolean
  mutatePjpCompanies: KeyedMutator<Data>
}

const usePjpCompanies = (
  searchText: string,
  page?: number,
  activeId?: string | null
): UsePjpCompaniesProps<PjpCompanyPropsPayload> => {
  const { data, error, mutate } = useSWRImmutable(
    PJP_COMPANY_URL({ searchText, page, limit: 8, activeId })
  )

  return {
    pjpCompaniesData: data as PjpCompanyPropsPayload,
    isLoadingPjpCompanies: !data && !error,
    mutatePjpCompanies: mutate,
  }
}

export default usePjpCompanies
