import { get, post } from '@helpers/fetch-wrapper'

import {
  GENERATE_OTP_URL,
  GENERATE_PS_OTP_URL,
  PJP_COMPANY_URL,
  SWITCH_COMPANY_URL,
  VERIFY_OTP_URL,
  VERIFY_PS_OTP_URL,
} from '@constants/url'

import { PjpCompanyProps } from '@custom-types/company'

import { StateProps } from '../reducers'

export type GenerateOtpProps = {
  countryCode: string
  phoneNumber: string
  transportMethod?: string
}

export type VerifyOtpProps = {
  otp?: string
  token: string
}

export type GenerateMagicLinkProps = {
  email: StateProps['email']
}

const generateOtpService = async (data: GenerateOtpProps, isPs: boolean) => {
  const res = await post(isPs ? GENERATE_PS_OTP_URL : GENERATE_OTP_URL, data)

  return res.data
}

const verifyOtpService = async (data: VerifyOtpProps, isPs: boolean) => {
  const res = await post(isPs ? VERIFY_PS_OTP_URL : VERIFY_OTP_URL, data)

  return res.data
}

const postMagicLink = async (data: GenerateMagicLinkProps) => {
  const res = await post(GENERATE_OTP_URL, data)

  return res.data
}

export const searchPjpCompanies = async (searchText: string) => {
  try {
    const res = await get(PJP_COMPANY_URL({ searchText }))
    const data: PjpCompanyProps[] = res.data.records

    return data
  } catch (e) {
    return []
  }
}

export const switchCompany = async (companyId: string) => {
  const res = await post(SWITCH_COMPANY_URL(companyId))

  return res.data
}

export { generateOtpService, verifyOtpService, postMagicLink }
