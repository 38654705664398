/* eslint-disable @next/next/no-html-link-for-pages */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import amplitude from 'amplitude-js'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { authActions } from '@features/auth/reducers'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'
import { profileActions } from '@features/profile/reducers'

import SwitchCompanyModal from '@components/modal/switch-company-modal/SwitchCompanyModal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import {
  ACCESS_CLICKED_ON_CONTACT_SUPPORT,
  Access,
  CLICK_ON_ACCOUNT_PAGE,
  CLICK_ON_CANDIDATE_PAGE,
  CLICK_ON_INTERVIEW_PAGE,
  CLICK_ON_LOKER_PAGE,
  CLICK_ON_TALENT_POOL_PAGE,
  SEARCH_CANDIDATE_CLICKED,
  TALENT_POOL_ENTRY_POINT,
} from '@constants/event-tracking'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import { ATMA_CS_PHONE_NUMBER } from '@constants/phone-number'
import {
  ATMA_EMPLOYER_ACCESS_TOKEN,
  ATMA_EMPLOYER_REFRESH_TOKEN,
} from '@constants/storage-items'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'
import shareToWhatsApp from '@constants/whatsapp-url'

import trackEvents from '@utils/track-events'

import { useTalentPoolActions } from '@store/talent-pool/slice'

interface PathIconProps extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean
}

const NavCandidateIcon = ({ isActive, ...restProps }: PathIconProps) => {
  const iconFillColor = isActive ? '#80CBC1' : 'white'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...restProps}
    >
      <path
        d="M18 2.83331H6C4.34 2.83331 3 4.16331 3 5.80331V16.7133C3 18.3533 4.34 19.6933 6 19.6933H6.76C7.55 19.6933 8.32 20.0033 8.88 20.5633L10.59 22.2533C11.37 23.0233 12.63 23.0233 13.41 22.2533L15.12 20.5633C15.68 20.0033 16.45 19.6933 17.24 19.6933H18C19.66 19.6933 21 18.3533 21 16.7133V5.80331C21 4.16331 19.66 2.83331 18 2.83331ZM12 6.38331C13.08 6.38331 13.95 7.26331 13.95 8.33331C13.95 9.39331 13.11 10.2433 12.07 10.2833H11.92C11.4146 10.2664 10.9357 10.0534 10.5848 9.68936C10.2338 9.32534 10.0384 8.83896 10.04 8.33331C10.05 7.26331 10.92 6.38331 12 6.38331ZM14.75 15.5233C13.24 16.5333 10.76 16.5333 9.25 15.5233C7.92 14.6433 7.92 13.1833 9.25 12.2933C10.77 11.2833 13.25 11.2833 14.75 12.2933C16.08 13.1833 16.08 14.6333 14.75 15.5233Z"
        fill={iconFillColor}
      />
    </svg>
  )
}

const NavLokerIcon = ({ isActive, ...restProps }: PathIconProps) => {
  const iconFillColor = isActive ? '#80CBC1' : 'white'

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M10 3C9.34219 3 8.8 3.55451 8.8 4.22727V5.45455H3.2C2.53906 5.45455 2 6.00586 2 6.68182V12C2 12.676 2.53906 13.2273 3.2 13.2273H20.8C21.4609 13.2273 22 12.676 22 12V6.68182C22 6.00586 21.4609 5.45455 20.8 5.45455H15.2V4.22727C15.2 3.55451 14.6578 3 14 3H10ZM10 3.81818H14C14.225 3.81818 14.4 3.99716 14.4 4.22727V5.45455H9.6V4.22727C9.6 3.99716 9.775 3.81818 10 3.81818ZM12 10.7727C12.4422 10.7727 12.8 11.1387 12.8 11.5909C12.8 12.0431 12.4422 12.4091 12 12.4091C11.5578 12.4091 11.2 12.0431 11.2 11.5909C11.2 11.1387 11.5578 10.7727 12 10.7727ZM2 12.8182V19.7727C2 20.4487 2.53906 21 3.2 21H20.8C21.4609 21 22 20.4487 22 19.7727V12.8182C22 13.4941 21.4609 14.0455 20.8 14.0455H3.2C2.53906 14.0455 2 13.4941 2 12.8182Z"
        fill={iconFillColor}
      />
    </svg>
  )
}

const NavLamaranIcon = ({ isActive, ...restProps }: PathIconProps) => {
  const iconFillColor = isActive ? '#80CBC1' : 'white'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M18 2H6C4.34 2 3 3.33 3 4.97V15.88C3 17.52 4.34 18.86 6 18.86H6.76C7.55 18.86 8.32 19.17 8.88 19.73L10.59 21.42C11.37 22.19 12.63 22.19 13.41 21.42L15.12 19.73C15.68 19.17 16.45 18.86 17.24 18.86H18C19.66 18.86 21 17.52 21 15.88V4.97C21 3.33 19.66 2 18 2ZM12 5.55C13.08 5.55 13.95 6.43 13.95 7.5C13.95 8.56 13.11 9.41 12.07 9.45H11.92C11.4146 9.4331 10.9357 9.22007 10.5848 8.85605C10.2338 8.49203 10.0384 8.00564 10.04 7.5C10.05 6.43 10.92 5.55 12 5.55ZM14.75 14.69C13.24 15.7 10.76 15.7 9.25 14.69C7.92 13.81 7.92 12.35 9.25 11.46C10.77 10.45 13.25 10.45 14.75 11.46C16.08 12.35 16.08 13.8 14.75 14.69Z"
        fill={iconFillColor}
      />
    </svg>
  )
}

const NavSearchCandidateIcon = ({ isActive, ...restProps }: PathIconProps) => {
  const iconFillColor = isActive ? '#80CBC1' : 'white'

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0909 11.0455C20.0909 6.05236 16.0385 2 11.0455 2C6.05236 2 2 6.05236 2 11.0455C2 16.0385 6.05236 20.0909 11.0455 20.0909C16.0385 20.0909 20.0909 16.0385 20.0909 11.0455ZM13.0556 8.0303C13.0556 9.14045 12.1556 10.0404 11.0455 10.0404C9.9353 10.0404 9.03535 9.14045 9.03535 8.0303C9.03535 6.92015 9.9353 6.0202 11.0455 6.0202C12.1556 6.0202 13.0556 6.92015 13.0556 8.0303ZM15.0657 13.0556C15.0657 14.1657 13.2657 15.0657 11.0455 15.0657C8.82516 15.0657 7.02525 14.1657 7.02525 13.0556C7.02525 11.9454 8.82516 11.0455 11.0455 11.0455C13.2657 11.0455 15.0657 11.9454 15.0657 13.0556Z"
        fill={iconFillColor}
      />
      <path
        d="M20.284 18.8626C19.8915 18.4702 19.2552 18.4703 18.8627 18.8627C18.4703 19.2552 18.4702 19.8915 18.8626 20.284L20.2835 21.7054C20.6761 22.0982 21.3128 22.0982 21.7055 21.7055C22.0982 21.3128 22.0982 20.6761 21.7054 20.2835L20.284 18.8626Z"
        fill="white"
      />
    </svg>
  )
}

const NavChatIcon = ({ isActive, ...restProps }: PathIconProps) => {
  const iconFillColor = isActive ? '#80CBC1' : 'white'

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4335 3.57748C19.6009 2.73909 18.5405 2.35971 17.1963 2.17798C15.8837 2 14.2009 2 12.053 2H11.0279C9.31907 2 7.98046 2 6.91721 2.11616C5.83349 2.23419 4.95442 2.47961 4.20465 3.02854C3.7506 3.36075 3.35129 3.76285 3.0214 4.22008C2.47628 4.97603 2.23256 5.85938 2.11535 6.95162C2 8.02232 2 9.3703 2 11.0911V11.1754C2 12.8962 2 14.2442 2.11535 15.3139C2.23256 16.4062 2.47628 17.2905 3.0214 18.0464C3.35129 18.5036 3.7506 18.9057 4.20465 19.2379C4.95535 19.7869 5.83256 20.0323 6.91721 20.1503C7.98046 20.2665 9.31907 20.2665 11.0279 20.2665H14.7888C15.3749 20.2665 15.6102 20.2674 15.8372 20.2946C16.2307 20.3433 16.614 20.4501 16.9749 20.6131C17.1842 20.7068 17.387 20.8285 17.8893 21.1311L17.9107 21.1442C18.3014 21.3803 18.6251 21.5761 18.893 21.7138C19.1619 21.8515 19.4493 21.9732 19.76 21.9948C20.1704 22.0239 20.5802 21.9306 20.9382 21.7265C21.2963 21.5224 21.5866 21.2166 21.773 20.8473C21.9135 20.5681 21.9591 20.2571 21.9795 19.9536C22 19.652 22 19.2717 22 18.8127V12.0166C22 9.85365 22 8.15909 21.8233 6.83641C21.6428 5.48375 21.2651 4.41586 20.4335 3.57748ZM7.34884 7.62046C7.1638 7.62046 6.98635 7.69448 6.85551 7.82623C6.72467 7.95799 6.65116 8.13669 6.65116 8.32302C6.65116 8.50935 6.72467 8.68804 6.85551 8.8198C6.98635 8.95155 7.1638 9.02557 7.34884 9.02557H14.7907C14.9757 9.02557 15.1532 8.95155 15.284 8.8198C15.4149 8.68804 15.4884 8.50935 15.4884 8.32302C15.4884 8.13669 15.4149 7.95799 15.284 7.82623C15.1532 7.69448 14.9757 7.62046 14.7907 7.62046H7.34884ZM6.65116 12.07C6.65116 11.8837 6.72467 11.705 6.85551 11.5732C6.98635 11.4415 7.1638 11.3674 7.34884 11.3674H11.0698C11.2548 11.3674 11.4323 11.4415 11.5631 11.5732C11.6939 11.705 11.7674 11.8837 11.7674 12.07C11.7674 12.2563 11.6939 12.435 11.5631 12.5668C11.4323 12.6985 11.2548 12.7725 11.0698 12.7725H7.34884C7.1638 12.7725 6.98635 12.6985 6.85551 12.5668C6.72467 12.435 6.65116 12.2563 6.65116 12.07Z"
        fill={iconFillColor}
      />
    </svg>
  )
}

const NavInterviewIcon = ({ isActive, ...restProps }: PathIconProps) => {
  const iconFillColor = isActive ? '#80CBC1' : 'white'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M8.25 5.75C7.84 5.75 7.5 5.41 7.5 5V2C7.5 1.59 7.84 1.25 8.25 1.25C8.66 1.25 9 1.59 9 2V5C9 5.41 8.66 5.75 8.25 5.75ZM15.75 5.75C15.34 5.75 15 5.41 15 5V2C15 1.59 15.34 1.25 15.75 1.25C16.16 1.25 16.5 1.59 16.5 2V5C16.5 5.41 16.16 5.75 15.75 5.75Z"
        fill={iconFillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.96 5.31C17.96 4.48 18.91 4.01 19.57 4.5C20.74 5.39 21.5 6.78 21.5 8.37V17.13C21.5 17.7695 21.374 18.4028 21.1293 18.9937C20.8846 19.5845 20.5258 20.1214 20.0736 20.5736C19.6214 21.0258 19.0845 21.3846 18.4937 21.6293C17.9028 21.874 17.2695 22 16.63 22H7.37C6.0784 22 4.83969 21.4869 3.92639 20.5736C3.01309 19.6603 2.5 18.4216 2.5 17.13V8.37C2.5036 8.11564 2.52364 7.86178 2.56 7.61C2.58 7.46 2.61 7.31 2.65 7.17L2.65 7.17C2.69 7.02 2.73 6.87 2.79 6.72C2.7956 6.70601 2.80041 6.6928 2.8051 6.67993C2.81716 6.64683 2.8284 6.61601 2.85 6.58C2.89 6.45 2.95 6.33 3.01 6.21C3.01 6.19 3.02 6.18 3.04 6.16C3.11816 6.00002 3.20842 5.84624 3.31 5.7C3.39 5.57 3.48 5.44 3.59 5.32C3.8 5.05 4.05 4.8 4.33 4.58C4.345 4.565 4.3625 4.5525 4.38 4.54C4.3975 4.5275 4.415 4.515 4.43 4.5C4.47 4.47 4.5 4.45 4.54 4.43C4.545 4.425 4.55 4.4225 4.555 4.42C4.56 4.4175 4.565 4.415 4.57 4.41C5.22 4.08 6.04 4.54 6.04 5.31V5.41C6.04 6.22 6.44 6.98 7.07 7.42C7.25458 7.53691 7.45271 7.63094 7.66 7.7C7.78 7.74 7.91 7.77 8.05 7.78C8.21 7.8 8.37 7.8 8.53 7.78C8.67 7.77 8.8 7.74 8.92 7.7C9.03 7.67 9.13 7.63 9.22 7.58L9.22001 7.58C9.32001 7.53 9.42 7.48 9.51 7.42C10.13 7.03 10.54 6.33 10.54 5.54V4.5C10.54 3.95 10.99 3.5 11.54 3.5H12.46C13.01 3.5 13.46 3.95 13.46 4.5V5.54C13.46 6.86 14.6 7.92 15.95 7.78C17.12 7.66 17.96 6.58 17.96 5.41V5.31ZM16.5064 11.5532C16.812 11.2735 16.8329 10.7991 16.5532 10.4936C16.2735 10.188 15.7991 10.1671 15.4936 10.4468L10.5586 14.9647L8.52762 12.9543C8.23323 12.6629 7.75837 12.6653 7.46697 12.9597C7.17558 13.2541 7.178 13.7289 7.47238 14.0203L10.0108 16.533C10.295 16.8143 10.75 16.8232 11.0449 16.5532L16.5064 11.5532Z"
        fill={iconFillColor}
      />
    </svg>
  )
}

const NavAccountIcon = ({ isActive, ...restProps }: PathIconProps) => {
  const iconFillColor = isActive ? '#80CBC1' : 'white'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M12.09 12C13.4161 12 14.6879 11.4732 15.6255 10.5355C16.5632 9.59785 17.09 8.32608 17.09 7C17.09 5.67392 16.5632 4.40215 15.6255 3.46447C14.6879 2.52678 13.4161 2 12.09 2C10.7639 2 9.49215 2.52678 8.55447 3.46447C7.61678 4.40215 7.09 5.67392 7.09 7C7.09 8.32608 7.61678 9.59785 8.55447 10.5355C9.49215 11.4732 10.7639 12 12.09 12ZM12.09 14.5C7.08 14.5 3 17.86 3 22C3 22.28 3.22 22.5 3.5 22.5H20.68C20.96 22.5 21.18 22.28 21.18 22C21.18 17.86 17.1 14.5 12.09 14.5Z"
        fill={iconFillColor}
      />
      <g style={{ mixBlendMode: 'darken' }}>
        <rect width="24" height="24" fill={iconFillColor} />
      </g>
    </svg>
  )
}

const WhatsAppIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1992_22408)">
      <path
        d="M12 3C7.02975 3 3 7.02975 3 12C3 13.6884 3.47426 15.2626 4.2832 16.6128L3.08057 21L7.56152 19.8237C8.87202 20.5688 10.3849 21 12 21C16.9703 21 21 16.9703 21 12C21 7.02975 16.9703 3 12 3ZM8.91943 7.80176C9.06568 7.80176 9.21595 7.80087 9.3457 7.80762C9.5062 7.81137 9.68089 7.82312 9.84814 8.19287C10.0469 8.63237 10.4797 9.73493 10.5352 9.84668C10.5907 9.95843 10.63 10.0901 10.5527 10.2363C10.4792 10.3863 10.441 10.4771 10.333 10.6099C10.2213 10.7389 10.0988 10.8991 9.99756 10.9966C9.88581 11.1083 9.77041 11.2309 9.89941 11.4536C10.0284 11.6764 10.4764 12.4066 11.1387 12.9961C11.9899 13.7566 12.7081 13.9903 12.9316 14.1021C13.1551 14.2138 13.2846 14.1964 13.4136 14.0464C13.5463 13.9001 13.9711 13.3983 14.1211 13.1748C14.2673 12.9513 14.4166 12.99 14.6191 13.0635C14.8246 13.137 15.9205 13.6768 16.144 13.7886C16.3675 13.9003 16.5141 13.9556 16.5703 14.0464C16.6281 14.1401 16.6281 14.5864 16.4429 15.1069C16.2576 15.6267 15.3479 16.1293 14.9399 16.1646C14.5282 16.2028 14.1439 16.3496 12.2637 15.6094C9.99567 14.7161 8.56536 12.3932 8.45361 12.2432C8.34186 12.0969 7.54541 11.0354 7.54541 9.94043C7.54541 8.84168 8.12149 8.30358 8.32324 8.08008C8.52874 7.85658 8.76943 7.80176 8.91943 7.80176Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1992_22408">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
)

const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75 11.73C7.75 11.9289 7.82902 12.1197 7.96967 12.2603C8.11032 12.401 8.30109 12.48 8.5 12.48H13.5V18C13.5 18.7956 13.1839 19.5587 12.6213 20.1213C12.0587 20.6839 11.2956 21 10.5 21H6.5C5.70435 21 4.94129 20.6839 4.37868 20.1213C3.81607 19.5587 3.5 18.7956 3.5 18V6C3.5 5.20435 3.81607 4.44129 4.37868 3.87868C4.94129 3.31607 5.70435 3 6.5 3H10.5C11.2956 3 12.0587 3.31607 12.6213 3.87868C13.1839 4.44129 13.5 5.20435 13.5 6V11H8.5C8.3045 10.9999 8.11671 11.0762 7.97662 11.2126C7.83653 11.3489 7.75521 11.5346 7.75 11.73Z"
      fill="white"
    />
    <path
      d="M21.19 12C21.1521 12.0893 21.0978 12.1707 21.03 12.24L17.43 15.84C17.2894 15.9805 17.0988 16.0594 16.9 16.0594C16.7012 16.0594 16.5106 15.9805 16.37 15.84C16.2296 15.6994 16.1507 15.5088 16.1507 15.31C16.1507 15.1113 16.2296 14.9207 16.37 14.78L18.69 12.46H13.5V11H18.69L16.37 8.66004C16.2963 8.59137 16.2372 8.50857 16.1962 8.41657C16.1552 8.32458 16.1332 8.22526 16.1314 8.12456C16.1296 8.02386 16.1482 7.92383 16.1859 7.83044C16.2236 7.73705 16.2797 7.65222 16.351 7.581C16.4222 7.50978 16.507 7.45363 16.6004 7.41591C16.6938 7.37819 16.7938 7.35967 16.8945 7.36144C16.9952 7.36322 17.0945 7.38526 17.1865 7.42626C17.2785 7.46725 17.3613 7.52635 17.43 7.60004L21 11.2C21.0678 11.2694 21.1221 11.3508 21.16 11.44C21.2017 11.5268 21.2258 11.6209 21.2309 11.717C21.2361 11.8131 21.2222 11.9093 21.19 12Z"
      fill="white"
    />
  </svg>
)

const MenuItemTitle = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => (
  <span className={classNames('text-[10px] text-white', className)}>
    {children}
  </span>
)

const MenuItemList = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <ul
    className={classNames('flex list-none flex-col xL:gap-y-[16px]', className)}
  >
    {children}
  </ul>
)

const AtmaLogo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.6026 21.5277C29.4742 22.1084 30.4675 22.4691 31.5011 22.5804L31.4985 22.5737C32.8455 22.8051 34.0892 23.46 35.0575 24.448C36.0258 25.436 36.6709 26.7083 36.9036 28.0888C37.1363 29.4693 36.9451 30.8899 36.3564 32.154C35.7678 33.4182 34.8107 34.4635 33.6176 35.1454C32.4245 35.8272 31.0544 36.112 29.6966 35.9602C28.3389 35.8085 27.0607 35.2277 26.0388 34.2982C25.0168 33.3688 24.3017 32.1366 23.9923 30.772C23.6828 29.4074 23.7943 27.978 24.3114 26.6813C24.6898 25.7283 24.8508 24.6992 24.7822 23.6727C24.7135 22.6461 24.4171 21.6492 23.9154 20.7582C23.4138 19.8671 22.7202 19.1055 21.8878 18.5314C21.0553 17.9574 20.1059 17.5861 19.1122 17.446C17.7605 17.2562 16.4997 16.6405 15.5039 15.6838C14.5082 14.7272 13.8267 13.4769 13.5537 12.1057C13.2807 10.7346 13.4297 9.31026 13.98 8.02971C14.5303 6.74916 15.4548 5.67557 16.6257 4.95735C17.7966 4.23912 19.1561 3.91172 20.5163 4.0204C21.8764 4.12908 23.1701 4.66848 24.2184 5.56403C25.2668 6.45958 26.0181 7.66707 26.3684 9.01959C26.7188 10.3721 26.6509 11.8029 26.1743 13.1141C25.7513 14.088 25.559 15.1507 25.6132 16.2153C25.6674 17.28 25.9665 18.3164 26.4861 19.2397C27.0056 20.163 27.7309 20.9471 28.6026 21.5277Z"
      fill="#80CBC1"
    />
    <path
      d="M16.1739 29.2398C16.1739 32.9715 13.2248 35.9967 9.58695 35.9967C5.94908 35.9967 3 32.9715 3 29.2398C3 25.5081 5.94908 22.483 9.58695 22.483C13.2248 22.483 16.1739 25.5081 16.1739 29.2398Z"
      fill="#80CBC1"
    />
  </svg>
)

const TalentPoolIcon = ({ isActive }: PathIconProps) => {
  const backgroundFillColor = isActive ? '#80CBC1' : 'white'
  const iconFillColor = isActive ? 'white' : '#4460AC'
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.625"
        y="0.625"
        width="24.75"
        height="24.75"
        rx="4.875"
        fill={backgroundFillColor}
      />
      <rect
        x="0.625"
        y="0.625"
        width="24.75"
        height="24.75"
        rx="4.875"
        stroke="#DCDBD6"
        strokeWidth="0.75"
      />
      <g clipPath="url(#clip0_2155_12464)">
        <circle
          cx="13"
          cy="9.25"
          r="2.25"
          stroke={iconFillColor}
          strokeWidth="1.125"
        />
        <path
          d="M13.2561 19H8.5C7.67157 19 7 18.3284 7 17.5C7 15.8431 8.34315 14.5 10 14.5H14.1458"
          stroke={iconFillColor}
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.875 13.75L18.6537 15.5252L20.5 15.7553L19.135 17.0826L19.4973 19L17.875 18.0452L16.2527 19L16.615 17.0826L15.25 15.7553L17.0963 15.5252L17.875 13.75Z"
          stroke={iconFillColor}
          strokeWidth="1.125"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2155_12464">
          <rect
            width="18"
            height="18"
            fill={backgroundFillColor}
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

/**
 *
 * @param string
 * @returns ex: "/" -> "" ; "/jobs" -> "jobs" ; "/chats/12341/" -> "chats"
 */
const getRootPathname = (string: string) => {
  const splitPathname = string.split('/')
  const result = splitPathname[1]
  return result
}

const SideNav = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const currPathname = router.pathname
  const userType = localStorage.getItem('userType')
  const isPs = userType === 'ps'
  const currRootPathname = getRootPathname(currPathname)
  const { company, isPhantom } = useAppSelector((state) => state.profileReducer)
  const { modalName } = useAppSelector((state) => state.appReducer)
  const { mutate } = useSWRConfig()
  const { data: employerProfileData, mutate: mutateEmployerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)

  const { hasNewPool } = useAppSelector((state) => state.pool)
  const { setHasNewPool } = useTalentPoolActions()

  return (
    <nav
      className={classNames(
        'h-full',
        'bg-primary-blue',
        'py-[20p] 2xl:py-[40px]',
        'flex',
        'flex-col',
        'justify-between',
        'items-center overflow-hidden'
      )}
    >
      <div className="flex flex-col xl:gap-y-4 items-center">
        <AtmaLogo />
        <Link href="/talent-pool" passHref legacyBehavior>
          <a
            href="/talent-pool"
            className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
            onClick={() => {
              if (
                employerProfileData &&
                (employerProfileData.kycStatus !== 'Approved' ||
                  employerProfileData.company?.kycStatus !== 'Approved')
              ) {
                mutateEmployerProfileData()
              }
              isPs
                ? trackEvents(CLICK_ON_TALENT_POOL_PAGE)
                : trackEvents(TALENT_POOL_ENTRY_POINT, {
                    category: 'JobProvider',
                  })
              setHasNewPool(false)
            }}
          >
            <div className="relative">
              <TalentPoolIcon
                isActive={currRootPathname === getRootPathname('/talent-pool')}
              />

              {hasNewPool && (
                <span className="bg-rejected-1 absolute rounded-full h-4 w-4 inline-block right-[-5px] bottom-[-6px]" />
              )}
            </div>
            <MenuItemTitle>Talent pool</MenuItemTitle>
          </a>
        </Link>
      </div>
      <MenuItemList>
        {userType === 'ps' && (
          <li>
            <Link href="/premium-service/candidate" passHref legacyBehavior>
              <a
                href="/premium-service/candidate"
                onClick={() => trackEvents(CLICK_ON_CANDIDATE_PAGE)}
                className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
              >
                <NavCandidateIcon
                  isActive={
                    currRootPathname === getRootPathname(PATHS.PS_CANDIDATE)
                  }
                />
                <MenuItemTitle>Kandidat</MenuItemTitle>
              </a>
            </Link>
          </li>
        )}
        {userType !== 'ps' && (
          <li>
            <Link href="/jobs" passHref legacyBehavior>
              <a
                href="/jobs"
                className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
                onClick={() => {
                  trackEvents(CLICK_ON_LOKER_PAGE)
                  if (
                    employerProfileData &&
                    (employerProfileData.kycStatus !== 'Approved' ||
                      employerProfileData.company?.kycStatus !== 'Approved')
                  ) {
                    mutateEmployerProfileData()
                  }
                }}
              >
                <NavLokerIcon
                  isActive={currRootPathname === getRootPathname(PATHS.JOBS)}
                />
                <MenuItemTitle>Loker</MenuItemTitle>
              </a>
            </Link>
          </li>
        )}
        {userType !== 'ps' && (
          <li>
            <Link href="/applications" passHref legacyBehavior>
              <a
                href="/applications"
                className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
                onClick={() => {
                  if (
                    employerProfileData &&
                    (employerProfileData.kycStatus !== 'Approved' ||
                      employerProfileData.company?.kycStatus !== 'Approved')
                  ) {
                    mutateEmployerProfileData()
                  }
                }}
              >
                <NavLamaranIcon
                  isActive={
                    currRootPathname === getRootPathname(PATHS.APPLICATIONS)
                  }
                />
                <MenuItemTitle>Lamaran</MenuItemTitle>
              </a>
            </Link>
          </li>
        )}
        {userType !== 'ps' && (
          <li>
            <Link href="/candidates/search" passHref legacyBehavior>
              <a
                href="/candidates/search"
                className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
                onClick={() => {
                  if (
                    employerProfileData &&
                    (employerProfileData.kycStatus !== 'Approved' ||
                      employerProfileData.company?.kycStatus !== 'Approved')
                  ) {
                    mutateEmployerProfileData()
                  }
                  trackEvents(SEARCH_CANDIDATE_CLICKED, {
                    entry_point: 'Main Menu',
                  })
                }}
              >
                <NavSearchCandidateIcon
                  isActive={
                    currRootPathname === getRootPathname(PATHS.SEARCH_CANDIDATE)
                  }
                />
                <MenuItemTitle>Cari</MenuItemTitle>
              </a>
            </Link>
          </li>
        )}
        {userType !== 'ps' && (
          <li>
            <Link href="/chats" passHref legacyBehavior>
              <a
                href="/chats"
                className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
                onClick={() => {
                  if (
                    employerProfileData &&
                    (employerProfileData.kycStatus !== 'Approved' ||
                      employerProfileData.company?.kycStatus !== 'Approved')
                  ) {
                    mutateEmployerProfileData()
                  }
                }}
              >
                <NavChatIcon
                  isActive={currRootPathname === getRootPathname(PATHS.CHAT)}
                />
                <MenuItemTitle>Chat</MenuItemTitle>
              </a>
            </Link>
          </li>
        )}
        <li>
          <Link href="/interviews" passHref legacyBehavior>
            <a
              href="/interviews"
              className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
              onClick={() => {
                isPs && trackEvents(CLICK_ON_INTERVIEW_PAGE)
                if (
                  employerProfileData &&
                  (employerProfileData.kycStatus !== 'Approved' ||
                    employerProfileData.company?.kycStatus !== 'Approved')
                ) {
                  mutateEmployerProfileData()
                }
              }}
            >
              <NavInterviewIcon
                isActive={
                  currRootPathname === getRootPathname(PATHS.INTERVIEWS)
                }
              />
              <MenuItemTitle>Interview</MenuItemTitle>
            </a>
          </Link>
        </li>

        <li>
          <Link href="/profile" passHref legacyBehavior>
            <a
              href="/profile"
              className="flex h-[56px] flex-col items-center justify-center gap-y-[4px]"
              onClick={() => {
                isPs && trackEvents(CLICK_ON_ACCOUNT_PAGE)
                if (
                  employerProfileData &&
                  (employerProfileData.kycStatus !== 'Approved' ||
                    employerProfileData.company?.kycStatus !== 'Approved')
                ) {
                  mutateEmployerProfileData()
                }
              }}
            >
              <NavAccountIcon
                isActive={currRootPathname === getRootPathname(PATHS.PROFILE)}
              />
              <MenuItemTitle>Akun</MenuItemTitle>
            </a>
          </Link>
        </li>
      </MenuItemList>

      <MenuItemList>
        {isPhantom && userType !== 'ps' && (
          <li className="flex justify-center">
            <button
              onClick={() =>
                dispatch(
                  appActions.setModalName(MODAL_NAME.SWITCH_COMPANY_MODAL)
                )
              }
              type="button"
            >
              <div className="relative h-10 w-10 overflow-hidden rounded-full border">
                <Image
                  alt="ic_empty"
                  src={
                    company?.logoURL || '/assets/icons/ic_empty_applicant.svg'
                  }
                  fill
                  sizes="100vw"
                />
              </div>
            </button>
          </li>
        )}
        <li>
          <a
            className="flex flex-col items-center"
            href={shareToWhatsApp('+62', ATMA_CS_PHONE_NUMBER)}
            onClick={() => {
              trackEvents(ACCESS_CLICKED_ON_CONTACT_SUPPORT)
            }}
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppIcon />
            <MenuItemTitle>Tanya CS</MenuItemTitle>
          </a>
        </li>
        <li>
          <div
            tabIndex={0}
            role="button"
            className="flex flex-col items-center"
            onClick={() => {
              const isPhantomUser = employerProfileData?.isPhantom
              dispatch(
                profileActions.setOpsStatus(
                  employerProfileData?.isPhantom as boolean
                )
              )

              // Clear SWR Cache
              // Doc reference: https://swr.vercel.app/docs/mutation#mutate-multiple-items
              mutate(() => true, undefined, { revalidate: false })

              if (userType === 'ps') {
                router.push('/ps-login')
              } else if (isPhantomUser) {
                router.push('/mac-login')
              } else {
                router.push('/login')
              }

              trackEvents(Access.LOGGED_OUT)
              dispatch(authActions.logout())
              dispatch(profileActions.removeProfile())
              amplitude.getInstance().setUserId(null)
              amplitude.getInstance().clearUserProperties()
              localStorage.removeItem('credentials')
              localStorage.removeItem(ATMA_EMPLOYER_ACCESS_TOKEN)
              localStorage.removeItem(ATMA_EMPLOYER_REFRESH_TOKEN)
              localStorage.removeItem('userType')
            }}
          >
            <LogoutIcon />
            <MenuItemTitle>Keluar</MenuItemTitle>
          </div>
        </li>
      </MenuItemList>
      <SwitchCompanyModal
        open={MODAL_NAME.SWITCH_COMPANY_MODAL === modalName}
        onClose={() => dispatch(appActions.removeModal())}
      />
    </nav>
  )
}

export default SideNav
